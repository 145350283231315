import { StrictMode, Suspense } from 'react';

import { I18nProvider } from '@emlo/i18n';
import { TopLoading } from '@emlo/components';

import ThemeProvider from './themes/ThemeProvider';

import i18n from './i18n';
import RouterProvider from './Router';

const App = () => {
  return (
    <StrictMode>
      <I18nProvider i18n={i18n}>
        <ThemeProvider>
          <Suspense fallback={<TopLoading />}>
            <RouterProvider />
          </Suspense>
        </ThemeProvider>
      </I18nProvider>
    </StrictMode>
  );
};

export default App;
