import { Grid, GridItem, Box, VStack, HStack } from '@chakra-ui/react';

import { Outlet, useMatch } from '@emlo/router';
import { RouterLink, HeadingRouterLink } from '@emlo/components';

import logo from 'assets/images/logo.png';

import bgWelcome from 'assets/images/bg-welcome.svg?url';
import bgLogin from 'assets/images/bg-login.svg?url';
import bgConsent from 'assets/images/bg-consent.svg?url';
import bgPassword from 'assets/images/bg-password.svg?url';
import bgLogout from 'assets/images/bg-logout.svg?url';
import bgError from 'assets/images/bg-error.svg?url';

import { PATHS } from 'Router';

const AccountLayout = () => {
  const isLoginPage = useMatch(PATHS.LOGIN);
  const isConsentPage = useMatch(PATHS.CONSENT);
  const isForgotPasswordPage = useMatch(PATHS.FORGOT_PASSWORD);
  const isLogoutPage = useMatch(PATHS.LOGOUT);
  const isErrorPage = useMatch(PATHS.ERROR);

  const getBackground = () => {
    if (isLoginPage) return bgLogin;

    if (isConsentPage) return bgConsent;

    if (isForgotPasswordPage) return bgPassword;

    if (isLogoutPage) return bgLogout;

    if (isErrorPage) return bgError;

    return bgWelcome;
  };

  return (
    <Grid
      templateColumns="repeat(12, 1fr)"
      h="100vh"
      bgColor="gray.100"
      bgImage={`url(${getBackground()})`}
      bgRepeat="no-repeat"
      bgSize="50%"
      bgPosition="80% center"
    >
      <GridItem
        colSpan={{
          base: 12,
          lg: 6,
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <VStack spacing="4">
          <HStack
            spacing="4"
            mb={{
              sm: 4,
              md: 6,
            }}
          >
            <RouterLink href="/" _hover={{ textDecoration: 'none' }}>
              <Box
                h="24"
                w="24"
                bgImage={`url(${logo})`}
                bgRepeat="no-repeat"
                bgPosition="center center"
                bgSize="contain"
              />
            </RouterLink>
            <HeadingRouterLink
              href="/"
              as="h1"
              fontSize="xxx-large"
              color="primary"
              sx={{
                '> a:hover': {
                  textDecoration: 'none',
                },
              }}
            >
              Emlo
            </HeadingRouterLink>
          </HStack>

          <Outlet />
        </VStack>
      </GridItem>
      <GridItem
        colSpan={{
          base: 0,
          lg: 6,
        }}
      />
    </Grid>
  );
};

export default AccountLayout;
