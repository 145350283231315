import { type RouteObject } from 'react-router';

import ProtectedRoute from './ProtectedRoute';

export type Route = Omit<RouteObject, 'children'> & {
  requireAuthenticated?: boolean;
  accessDeniedPath?: string;
  isAllowed?: () => boolean;
  children?: Array<Route>;
};

const generateRoutes = (
  routes: Array<Route>,
  redirectPath?: string,
  isAuthenticated?: () => boolean
): Array<RouteObject> =>
  routes.map((route) => {
    const { requireAuthenticated, isAllowed, accessDeniedPath, children, index, ...restRoute } =
      route;

    if (requireAuthenticated && !!isAuthenticated && redirectPath) {
      return {
        ...restRoute,
        element: (
          <ProtectedRoute
            redirectPath={redirectPath}
            isAuthenticated={isAuthenticated}
            accessDeniedPath={accessDeniedPath}
            isAllowed={isAllowed}
          >
            {restRoute.element}
          </ProtectedRoute>
        ),
      };
    }

    if (children) {
      return {
        ...restRoute,
        children: generateRoutes(children, redirectPath, isAuthenticated),
        index: false,
      };
    }

    return {
      ...restRoute,
      index,
    };
  });

export default generateRoutes;
