import { Heading, type HeadingProps } from '@chakra-ui/react';
import RouterLink from './RouterLink';

type Props = HeadingProps & {
  href: string;
};

const HeadingRouterLink = (props: Props) => {
  const { href, children, ...rest } = props;

  return (
    <Heading {...rest}>
      <RouterLink href={href}>{children}</RouterLink>
    </Heading>
  );
};

export default HeadingRouterLink;
