import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
  type InputProps,
} from '@chakra-ui/react';

export type TextInputProps = InputProps & {
  label?: React.ReactNode;
  helperText?: React.ReactNode;
  errorMessage?: React.ReactNode;

  leftAddon?: React.ReactNode;
  leftElement?: React.ReactNode;

  rightAddon?: React.ReactNode;
  rightElement?: React.ReactNode;
};

const TextInput = (props: TextInputProps) => {
  const {
    isReadOnly,
    isDisabled,
    isRequired,
    isInvalid,
    label,
    helperText,
    errorMessage,

    leftAddon,
    leftElement,

    rightAddon,
    rightElement,

    className,
    sx,

    ...rest
  } = props;

  const renderHelper = () =>
    isInvalid
      ? errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>
      : helperText && <FormHelperText>{helperText}</FormHelperText>;

  return (
    <FormControl
      isReadOnly={isReadOnly}
      isDisabled={isDisabled}
      isRequired={isRequired}
      isInvalid={isInvalid}
      className={className}
      sx={{ ...sx }}
    >
      {label && <FormLabel fontWeight="normal">{label}</FormLabel>}
      <InputGroup>
        {leftAddon && <InputLeftAddon>{leftAddon}</InputLeftAddon>}
        {leftElement && <InputLeftElement>{leftElement}</InputLeftElement>}
        <Input {...rest} />
        {rightElement && <InputRightElement>{rightElement}</InputRightElement>}
        {rightAddon && <InputRightAddon p="0">{rightAddon}</InputRightAddon>}
      </InputGroup>
      {renderHelper()}
    </FormControl>
  );
};

export default TextInput;
