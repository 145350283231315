import { ChakraProvider, ColorModeScript, CSSReset } from '@chakra-ui/react';
import theme from './theme';

type Props = {
  children: React.ReactNode;
};

const ThemeProvider = (props: Props) => {
  return (
    <>
      <ColorModeScript
        initialColorMode={theme.config.initialColorMode}
        storageKey="sisa-theme-mode"
        type="cookie"
      />
      <ChakraProvider theme={theme}>
        <CSSReset />
        {props.children}
      </ChakraProvider>
    </>
  );
};

export default ThemeProvider;
