import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  type CheckboxProps,
} from '@chakra-ui/react';

export type CheckBoxInputProps = Omit<CheckboxProps, 'children'> & {
  label?: React.ReactNode;
  helperText?: React.ReactNode;
  errorMessage?: React.ReactNode;
};

const CheckBoxInput = (props: CheckBoxInputProps) => {
  const {
    isReadOnly,
    isDisabled,
    isRequired,
    isInvalid,
    label,
    helperText,
    errorMessage,

    className,
    sx,

    ...rest
  } = props;

  const renderHelper = () =>
    isInvalid ? (
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    ) : (
      <FormHelperText>{helperText}</FormHelperText>
    );

  return (
    <FormControl
      isReadOnly={isReadOnly}
      isDisabled={isDisabled}
      isRequired={isRequired}
      isInvalid={isInvalid}
      className={className}
      sx={{ ...sx }}
    >
      <Checkbox {...rest}>{label}</Checkbox>
      {renderHelper()}
    </FormControl>
  );
};

export default CheckBoxInput;
