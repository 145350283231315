import { Link, type LinkProps } from '@chakra-ui/react';
import LinkBehavior from './LinkBehavior';

type Props = Omit<LinkProps, 'as'>;

const RouterLink = (props: Props) => {
  return <Link as={LinkBehavior} {...props} />;
};

export default RouterLink;
