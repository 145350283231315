import { Button, type ButtonProps } from '@chakra-ui/react';
import LinkBehavior from './LinkBehavior';

type Props = Omit<ButtonProps, 'as'> & {
  href: string;
};

const ButtonRouterLink = (props: Props) => {
  return <Button as={LinkBehavior} {...props} />;
};

export default ButtonRouterLink;
