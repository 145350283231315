import { Text, type TextProps } from '@chakra-ui/react';
import LinkBehavior from './LinkBehavior';

type Props = Omit<TextProps, 'as'> & {
  href: string;
};

const TextRouterLink = (props: Props) => {
  return <Text as={LinkBehavior} {...props} />;
};

export default TextRouterLink;
