import { createBrowserRouter } from 'react-router-dom';

import generateRoutes, { type Route } from 'generateRoutes';

const createRouter = (
  routes: Array<Route>,
  basePath?: string,
  redirectPath?: string,
  isAuthenticated?: () => boolean
) => {
  const generatedRoutes = generateRoutes(routes, redirectPath, isAuthenticated);

  return createBrowserRouter(generatedRoutes, {
    basename: basePath ?? '/',
  });
};

export default createRouter;
