import { useState, type MouseEvent } from 'react';

import { Icon, IconButton } from '@chakra-ui/react';
import { IconEye, IconEyeOff } from '@tabler/icons-react';

import TextInput, { type TextInputProps } from './TextInput';

export type PasswordInputProps = Omit<TextInputProps, 'type' | 'rightElement'>;

const PasswordInput = (props: PasswordInputProps) => {
  const [visible, setVisible] = useState(false);

  const togglePasswordVisibilityHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setVisible(!visible);
  };

  const renderInputType = () => ({
    type: visible ? 'text' : 'password',
  });

  const renderToggleIcon = () => <Icon as={visible ? IconEye : IconEyeOff} fontSize="lg" />;

  const renderTogglePasswordControl = () => (
    <IconButton
      aria-label="Toggle Password visibility"
      size="sm"
      color="gray"
      variant="ghost"
      onClick={togglePasswordVisibilityHandler}
      icon={renderToggleIcon()}
    />
  );

  return (
    <TextInput {...props} {...renderInputType()} rightElement={renderTogglePasswordControl()} />
  );
};

export default PasswordInput;
