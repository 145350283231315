import { lazy, Suspense } from 'react';
import { createRouter, Route, RouterProvider as BaseRouterProvider } from '@emlo/router';

import AccountLayout from 'pages/account/AccountLayout';
import NotFoundPage from 'pages/shared/NotFoundPage';

const WelcomePage = lazy(() => import('pages/account/WelcomePage'));
const AccessDeniedPage = lazy(() => import('pages/account/AccessDeniedPage'));
const ErrorPage = lazy(() => import('pages/account/ErrorPage'));

const RegisterPage = lazy(() => import('pages/account/RegisterPage'));

const LoginPage = lazy(() => import('pages/account/LoginPage'));
const ExternalLoginCallbackPage = lazy(() => import('pages/account/ExternalLoginCallbackPage'));
const LoginWith2faPage = lazy(() => import('pages/account/LoginWith2faPage'));
const LoginWithRecoveryCodePage = lazy(() => import('pages/account/LoginWithRecoveryCodePage'));

const ConsentPage = lazy(() => import('pages/account/ConsentPage'));

const ConfirmEmailPage = lazy(() => import('pages/account/ConfirmEmailPage'));

const ForgotPasswordPage = lazy(() => import('pages/account/ForgotPasswordPage'));
const ForgotPasswordConfirmationPage = lazy(
  () => import('pages/account/ForgotPasswordConfirmationPage')
);

const ResetPasswordPage = lazy(() => import('pages/account/ResetPasswordPage'));
const ResetPasswordConfirmationPage = lazy(
  () => import('pages/account/ResetPasswordConfirmationPage')
);

const LockoutPage = lazy(() => import('pages/account/LockoutPage'));

const LogoutPage = lazy(() => import('pages/account/LogoutPage'));

export const PATHS = {
  ROOT: '/',
  NOT_FOUND: '*',

  WELCOME: '/',
  ACCESS_DENIED: '/access-denied',
  ERROR: '/error',

  REGISTER: '/register',

  LOGIN: '/login',
  EXTERNAL_LOGIN_CALLBACK: '/external-login-callback',
  LOGIN_WITH_2FA: '/login-with-2fa',
  LOGIN_WITH_RECOVERY_CODE: '/login-with-recovery-code',

  CONSENT: '/consent',

  CONFIRM_EMAIL: '/confirm-email',

  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_CONFIRMATION: '/forgot-password-confirmation',

  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_CONFIRMATION: '/reset-password-confirmation',

  LOCKOUT: '/lockout',
  LOGOUT: '/logout',
};

const routes: Array<Route> = [
  {
    path: PATHS.ROOT,
    element: <AccountLayout />,
    children: [
      {
        path: PATHS.WELCOME,
        element: (
          <Suspense fallback={<></>}>
            <WelcomePage />
          </Suspense>
        ),
      },
      {
        path: PATHS.ACCESS_DENIED,
        element: (
          <Suspense fallback={<></>}>
            <AccessDeniedPage />
          </Suspense>
        ),
      },
      {
        path: PATHS.ERROR,
        element: (
          <Suspense fallback={<></>}>
            <ErrorPage />
          </Suspense>
        ),
      },

      {
        path: PATHS.REGISTER,
        element: (
          <Suspense fallback={<></>}>
            <RegisterPage />
          </Suspense>
        ),
      },

      {
        path: PATHS.LOGIN,
        element: (
          <Suspense fallback={<></>}>
            <LoginPage />
          </Suspense>
        ),
      },
      {
        path: PATHS.EXTERNAL_LOGIN_CALLBACK,
        element: (
          <Suspense fallback={<></>}>
            <ExternalLoginCallbackPage />
          </Suspense>
        ),
      },
      {
        path: PATHS.LOGIN_WITH_2FA,
        element: (
          <Suspense fallback={<></>}>
            <LoginWith2faPage />
          </Suspense>
        ),
      },
      {
        path: PATHS.LOGIN_WITH_RECOVERY_CODE,
        element: (
          <Suspense fallback={<></>}>
            <LoginWithRecoveryCodePage />
          </Suspense>
        ),
      },

      {
        path: PATHS.CONSENT,
        element: (
          <Suspense fallback={<></>}>
            <ConsentPage />
          </Suspense>
        ),
      },

      {
        path: PATHS.CONFIRM_EMAIL,
        element: (
          <Suspense fallback={<></>}>
            <ConfirmEmailPage />
          </Suspense>
        ),
      },

      {
        path: PATHS.FORGOT_PASSWORD,
        element: (
          <Suspense fallback={<></>}>
            <ForgotPasswordPage />
          </Suspense>
        ),
      },
      {
        path: PATHS.FORGOT_PASSWORD_CONFIRMATION,
        element: (
          <Suspense fallback={<></>}>
            <ForgotPasswordConfirmationPage />
          </Suspense>
        ),
      },
      {
        path: PATHS.RESET_PASSWORD,
        element: (
          <Suspense fallback={<></>}>
            <ResetPasswordPage />
          </Suspense>
        ),
      },
      {
        path: PATHS.RESET_PASSWORD_CONFIRMATION,
        element: (
          <Suspense fallback={<></>}>
            <ResetPasswordConfirmationPage />
          </Suspense>
        ),
      },

      {
        path: PATHS.LOCKOUT,
        element: (
          <Suspense fallback={<></>}>
            <LockoutPage />
          </Suspense>
        ),
      },
      {
        path: PATHS.LOGOUT,
        element: (
          <Suspense fallback={<></>}>
            <LogoutPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: PATHS.NOT_FOUND,
    element: (
      <Suspense fallback={<></>}>
        <NotFoundPage />
      </Suspense>
    ),
  },
];

const RouterProvider = () => {
  const router = createRouter(routes, '/');

  return <BaseRouterProvider router={router} fallbackElement={<NotFoundPage />} />;
};

export default RouterProvider;
