import { ChangeEvent } from 'react';

import { RowData, Column } from '@tanstack/react-table';

import { Flex, FormControl, FormLabel, Icon, IconButton, Spacer, Switch } from '@chakra-ui/react';
import {
  IconArrowDown,
  IconArrowsSort,
  IconArrowUp,
  IconGripHorizontal,
  IconPin,
} from '@tabler/icons-react';
import { Reorder, useDragControls, useMotionValue } from 'framer-motion';

type Props<TData extends RowData, TValue> = {
  column: Column<TData, TValue>;
  isChildren: boolean;
};

const SettingsActionsItem = <TData extends RowData, TValue>(props: Props<TData, TValue>) => {
  const childColumns = props.column.columns || [];
  const isVisible =
    childColumns.length === 0
      ? props.column.getIsVisible()
      : props.column.getLeafColumns().some((c) => c.getIsVisible());

  const y = useMotionValue(0);
  const dragControls = useDragControls();

  const handleToggleVisibility = (
    event: ChangeEvent<HTMLInputElement>,
    column: Column<TData, TValue>
  ) => {
    event.preventDefault();

    if (column.columns.length === 0) column.toggleVisibility(!isVisible);
    else column.getLeafColumns().forEach((c) => c.toggleVisibility(!isVisible));
  };

  return (
    <>
      {!props.isChildren ? (
        <Reorder.Item
          value={props.column.id}
          style={{ y }}
          dragListener={false}
          dragControls={dragControls}
        >
          <Flex gap={2} direction="row" alignItems="normal">
            <IconButton
              aria-label="drag-drop"
              size="xs"
              icon={<Icon as={IconGripHorizontal} fontSize="lg" />}
              onPointerDown={(event) => dragControls.start(event)}
            />
            <FormControl
              as={Flex}
              direction="row"
              gap={2}
              alignItems="baseline"
              ml={props.column.depth * 8}
            >
              <Switch
                isChecked={isVisible}
                onChange={(event) => handleToggleVisibility(event, props.column)}
                size="sm"
              />
              <FormLabel>{props.column.columnDef.header}</FormLabel>
            </FormControl>
            <Spacer />
            <IconButton
              aria-label="sort"
              isRound
              size="xs"
              icon={
                <Icon
                  as={
                    !props.column.getIsSorted()
                      ? IconArrowsSort
                      : props.column.getIsSorted() === 'desc'
                      ? IconArrowDown
                      : IconArrowUp
                  }
                  fontSize="lg"
                />
              }
              isDisabled={!props.column.getCanSort()}
              onClick={props.column.getToggleSortingHandler()}
            />
            <IconButton
              aria-label="pin-left"
              isRound
              isDisabled={!props.column.getCanPin()}
              size="xs"
              icon={<Icon as={IconPin} fontSize="lg" />}
            />
            <IconButton
              aria-label="pin-right"
              isRound
              isDisabled={!props.column.getCanPin()}
              size="xs"
              icon={<Icon as={IconPin} fontSize="lg" transform="rotate(270deg)" />}
            />
          </Flex>
          {childColumns.map((column) => (
            <SettingsActionsItem key={column.id} column={column} isChildren={true} />
          ))}
        </Reorder.Item>
      ) : (
        <>
          <Flex gap={2} direction="row" alignItems="normal">
            <IconButton
              aria-label="drag-drop"
              size="xs"
              icon={<Icon as={IconGripHorizontal} fontSize="lg" />}
              onPointerDown={(event) => dragControls.start(event)}
            />
            <FormControl
              as={Flex}
              direction="row"
              gap={2}
              alignItems="baseline"
              ml={props.column.depth * 8}
            >
              <Switch
                isChecked={isVisible}
                onChange={(event) => handleToggleVisibility(event, props.column)}
                size="sm"
              />
              <FormLabel>{props.column.columnDef.header}</FormLabel>
            </FormControl>
            <Spacer />
            <IconButton
              aria-label="sort"
              isRound
              size="xs"
              icon={
                <Icon
                  as={
                    !props.column.getIsSorted()
                      ? IconArrowsSort
                      : props.column.getIsSorted() === 'desc'
                      ? IconArrowDown
                      : IconArrowUp
                  }
                  fontSize="lg"
                />
              }
              isDisabled={!props.column.getCanSort()}
              onClick={props.column.getToggleSortingHandler()}
            />
            <IconButton
              aria-label="pin-left"
              isRound
              isDisabled={!props.column.getCanPin()}
              size="xs"
              icon={<Icon as={IconPin} fontSize="lg" />}
            />
            <IconButton
              aria-label="pin-right"
              isRound
              isDisabled={!props.column.getCanPin()}
              size="xs"
              icon={<Icon as={IconPin} fontSize="lg" transform="rotate(270deg)" />}
            />
          </Flex>
          {childColumns.map((column) => (
            <SettingsActionsItem key={column.id} column={column} isChildren={true} />
          ))}
        </>
      )}
    </>
  );
};

export default SettingsActionsItem;
