import '@fontsource/public-sans/300.css';
import '@fontsource/public-sans/300-italic.css';

import '@fontsource/public-sans/400.css';
import '@fontsource/public-sans/400-italic.css';

import '@fontsource/public-sans/500.css';
import '@fontsource/public-sans/500-italic.css';

import '@fontsource/public-sans/600.css';
import '@fontsource/public-sans/600-italic.css';

import '@fontsource/public-sans/700.css';
import '@fontsource/public-sans/700-italic.css';

import { extendTheme } from '@chakra-ui/react';

const lightTheme = extendTheme({
  config: {
    cssVarPrefix: 'sisa',
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    heading: `"Public Sans", sans-serif`,
    body: `"Public Sans", sans-serif`,
    mono: `"Public Sans", sans-serif`,
  },
  colors: {
    primary: {
      50: '#def6ff',
      100: '#b7defa',
      200: '#8ec6f1',
      300: '#62afe8',
      400: '#3898e0',
      500: '#1f7fc7',
      600: '#13639c',
      700: '#074770',
      800: '#002a46',
      900: '#000f1d',
    },
    secondary: {
      50: '#ebf4f9',
      100: '#d4dcde',
      200: '#bac4c6',
      300: '#9fadb0',
      400: '#849699',
      500: '#6a7c7f',
      600: '#526163',
      700: '#3a4547',
      800: '#212a2b',
      900: '#031010',
    },
    info: {
      50: '#d8fcff',
      100: '#aaefff',
      200: '#7ae3ff',
      300: '#48d7ff',
      400: '#1acbfe',
      500: '#01b2e5',
      600: '#008ab3',
      700: '#006381',
      800: '#003c50',
      900: '#00161e',
    },
    success: {
      50: '#ddfefa',
      100: '#b8f4ec',
      200: '#91ece0',
      300: '#68e4d4',
      400: '#41dcc7',
      500: '#2ac3ae',
      600: '#1b9787',
      700: '#0d6c61',
      800: '#00423a',
      900: '#001813',
    },
    warning: {
      50: '#ffecdc',
      100: '#ffcbaf',
      200: '#ffa97e',
      300: '#ff884c',
      400: '#ff661a',
      500: '#e64d00',
      600: '#b43a00',
      700: '#812a00',
      800: '#4f1700',
      900: '#210600',
    },
    error: {
      50: '#ffe6e3',
      100: '#fcbdb9',
      200: '#f3938c',
      300: '#ed685f',
      400: '#e63f32',
      500: '#cd2519',
      600: '#a01c12',
      700: '#73130c',
      800: '#470805',
      900: '#1f0000',
    },
  },
  semanticTokens: {
    colors: {
      primary: {
        _light: 'primary.500',
        _dark: 'primary.800',
      },
      secondary: {
        _light: 'secondary.500',
        _dark: 'secondary.800',
      },
      info: {
        _light: 'info.500',
        _dark: 'info.800',
      },
      success: {
        _light: 'success.500',
        _dark: 'success.800',
      },
      warning: {
        _light: 'warning.500',
        _dark: 'warning.800',
      },
      error: {
        _light: 'error.500',
        _dark: 'error.800',
      },
    },
  },
});

export default lightTheme;
