import { createI18n } from '@emlo/i18n';

import en from './locales/en.json';
import vi from './locales/vi.json';

export const fallbackLng = 'en';
export const defaultNS = 'translation';
export const supportedLngs = ['en', 'vi'];
export const resources = {
  en: {
    translation: en,
  },
  vi: {
    translation: vi,
  },
};

const i18n = createI18n(resources, fallbackLng, supportedLngs, defaultNS);

export default i18n;
