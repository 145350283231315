import { Box, Progress } from '@chakra-ui/react';

const TopLoading = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 999999,
        width: '100%',
      }}
    >
      <Progress size="xs" isIndeterminate />
    </Box>
  );
};

export default TopLoading;
